export function getProductCategories(): Promise<ProductCategory[]> {
    return Promise.resolve<ProductCategory[]>([
        {
            id: 1,
            name: 'Slalomski'
        },
        {
            id: 2,
            name: 'Skalljakke'
        },
        {
            id: 3,
            name: 'Votter'
        },
        {
            id: 4,
            name: 'Tursekk'
        },
        {
            id: 5,
            name: 'Langrennsski'
        },
        {
            id: 6,
            name: 'Turtelt'
        },
    ]);
}

interface ProductCategory {
    id: number;
    name: string;
}